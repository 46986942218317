const colors = {
  primary: '#03A9F4',
  accent: '#03A9F4',
  primaryText: '#212121',
  secondaryText: '#757575',
  white: '#fff',
  black: '#000',
  transparent: 'rgba(0,0,0,0)',
};

const components = {
  body: {
    backgroundColor:
      'linear-gradient(45deg, rgb(166, 206, 244), rgb(200, 213, 226), rgb(200, 213, 226), rgb(143, 177, 209))',
  },
  pageHeader: {
    backgroundColor: colors.transparent,
    headlineFontColor: colors.primaryText,
    headlineFontSize: '56px',
    headlineFontSizeSmall: '36px',
    subHeadlineFontColor: colors.secondaryText,
    subHeadlineFontSize: '24px',
    subHeadlineFontSizeSmall: '18px',
  },
  appList: {
    backgroundColor: colors.transparent,
    headlineFontColor: colors.primaryText,
    headlineFontSize: '28px',
    subTextFontColor: colors.secondaryText,
    subTextFontSize: '18px',
  },
  footer: {
    backgroundColor: colors.transparent,
    linkColor: colors.secondaryText,
    copyrightColor: colors.secondaryText,
  },
  imprintContent: {
    headlineFontColor: colors.primaryText,
    headlineFontSize: '36px',
    subHeadlineFontColor: colors.primaryText,
    subHeadlineFontSize: '20px',
    textFontColor: colors.secondaryText,
    textFontSize: '16px',
  },
  privacyContent: {
    headlineFontColor: colors.primaryText,
    headlineFontSize: '36px',
    subHeadlineFontColor: colors.primaryText,
    subHeadlineFontSize: '20px',
    textFontColor: colors.secondaryText,
    textFontSize: '16px',
  },
};

const theme = {
  colors,
  components,
};

export default theme;
