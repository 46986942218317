import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'SourceSansPro';
    font-weight: normal;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  body {
    line-height: 1.5;
    letter-spacing: 0;
    background: ${({ theme }) => theme.components.body.backgroundColor};
  }

  #gatsby-focus-wrapper {
    width: 100%;
  }
`;
