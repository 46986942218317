import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GatsbyBrowser } from 'gatsby';

import { GlobalStyle } from './src/theme/global-style';
import './src/theme/fonts.css';
import theme from './src/theme/theme';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
);
